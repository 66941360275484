import '../styles/Home.css'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Banner from '../assets/photos/Home Banner.png'
import { Stack } from 'react-bootstrap'
// import { Nav } from 'react-bootstrap'
import ApplePodcasts from '../assets/icons/US-UK_Apple_Podcasts_Listen_Badge_RGB_062023.svg'
import Youtube from '../assets/icons/AvailableonYouTube-black-SVG.svg'
import AmazonMusic from '../assets/icons/US_ListenOn_AmazonMusic_Button_Charcoal.png'
import Spotify from '../assets/icons/Listen-on-Spotify-Logo-Vector.svg-.png'

const Home = () => {
    const streamingBadges = [
        {
            "host":"apple-podcasts",
            "href":"https://podcasts.apple.com/us/podcast/a-hard-days-late-night-with-ira/id1780170109",
            "badge-src": ApplePodcasts,
            "alt": "Apple podcasts badge"
        },
        {
            "host": "youtube",
            "href": "https://www.youtube.com/playlist?list=PLwaQlX62LDS5XqpoebSR6X2yrD1S0uxvy",
            "badge-src": Youtube,
            "alt": "YouTube badge"
        }, 
        {
            "host": "amazon-music",
            "href": "https://music.amazon.com/podcasts/200cb747-f725-4cba-bcc1-13395fba7bbd/a-hard-days-late-night-with-ira",
            "badge-src": AmazonMusic,
            "alt": "Amazon music badge"
        },
        {
            "host": "spotify",
            "href": "https://open.spotify.com/show/3Nz6LLhT3NPx9HwVQnqWE6?si=8b253d29bd1249f8",
            "badge-src": Spotify,
            "alt": "Spotify badge"
        }
    ]

    const badgeAnchors = streamingBadges.map((badge)=>{
        return <a
        className='podcast-badge'
        id={badge['host']}
        href={badge['href']}>
            <img
                className='podcast-badge-image' 
                src={badge['badge-src']} 
                alt={badge.alt} />
        </a>
    })

    return (
        <div id='home-container'>
            <div className='banner'>
                <Image
                    src={Banner}
                    alt='Podcast Banner'
                    className='banner-image'
                    fluid />
            </div>
            <Container fluid>
                <Stack>
                    <Container className='subscribe-container '>
                        <h3 className='subscribe'>Subscribe</h3>
                        <Container className='links-box'>
                            <Stack 
                                direction='horizontal' 
                                className='hosting-links mx-auto'
                                gap={3}
                                fluid
                                >
                                {badgeAnchors}
                            </Stack>
                        </Container>
                    </Container>
                    <Container className='description-box my-3'>
                        <Container className='description-text-box'>
                            <p className='description'>A Hard Day’s Late with Ira was born of debauchery and desperation.</p>
                            <p className='description'>Having attained international acclaim as the formidable drummer with indie
                                rock icons Nada Surf, topping lists of the generation’s most influential musicians and eating
                                in restaurants where the guy comes to table with the giant pepper grinder;
                                Ira Elliot found himself at a crossroads.</p>
                            <p className='description'>For three decades he had traveled the world, playing five-hour-a-night
                                marathon shows, fending off legions of horned-up and gender-inconsistent groupies,
                                indulging in an array of incompatible drugs and sleeping restfully ten to twelve hours a
                                night while looking good doing it.</p>
                            <p className='description'>But what did it all mean? What was there possibly left to achieve?</p>
                            <p className='description'>Oh, sure he could have easily returned to his first love, orthodonture.
                                No one can attach an incisor bracket firmer or faster. But where was the glory?
                                Where was the danger? Where was the cash money at?</p>
                            <p className='description'>And the answer was suddenly plain as day:  <i>podcasting</i>.</p>
                            <p className='description'>And so, combining his long-held dream of being a late night talk show host like
                                Pat Sajak and Chevy Chase, with his unremitting love of the Beatles like Pete Best and Billy
                                Preston, he turned his steady, Lasik-corrected, monovision gaze to the internets
                                and A Hard Day’s Late Night with Ira is the result.</p>
                            <p className='description'>Now you too can bask in the questionable intelligence and half-baked
                                wisdom of the man that Modern Drummer magazine once called “that guy who just won’t stop
                                texting us in the middle of the night.” The court documents are available to prove it.</p>
                        </Container>
                    </Container>
                    {/* <Container>
                        <iframe
                            className='podcast-player'
                            title="A Hard Day’s Late Night With Ira"
                            allowtransparency="true"
                            scrolling="no"
                            data-name="pb-iframe-player"
                            src="https://www.podbean.com/player-v2/?i=wr9vx-12a0847-pbblog-playlist&share=1&download=1&rtl=0&fonts=Arial&skin=38363E&font-color=auto&logo_link=podcast_page&order=episodic&limit=10&filter=all&ss=a713390a017602015775e868a2cf26b0&btn-skin=014282&size=240"
                            loading="lazy"
                            allowfullscreen=""></iframe>
                    </Container> */}
                </Stack>
            </Container>
        </div>
    )
}

export default Home